'use client';

import config from '@haaretz/l-config';
import cleanConfig from '@haaretz/l-config-shim/cleanConfig';

export default function ClientConfigScript() {
  return (
    <script
      suppressHydrationWarning
      dangerouslySetInnerHTML={{
        __html: `
            window.__HTZ_CONFIG__ = window.__HTZ_CONFIG__ || ${JSON.stringify(cleanConfig(config))}
            `,
      }}
    />
  );
}
