import type { IConfig } from 'config';

export default function cleanConfig(config: IConfig) {
  const nodeConfig = { ...config };

  // WARN: Be very careful with making changes here. This is a security measure to prevent
  //       leaking secrets from the server to the client.
  const keysToRemove = ['Cluster', 'SiteId', 'ApiKey', 'praedicta'];
  Object.keys(nodeConfig).forEach(key => {
    if (keysToRemove.some(term => key.includes(term))) {
      delete (nodeConfig as Record<string, unknown>)[key];
    }
  });

  return nodeConfig;
}
