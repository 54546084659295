import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.0.3_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_@playwright+test@1.49.1_4djjr5g64p5jtkgcav3xcc6gn4/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.0.3_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_@playwright+test@1.49.1_4djjr5g64p5jtkgcav3xcc6gn4/node_modules/next/font/google/target.css?{\"path\":\"../../utils/s-next-font-merriweather/dist/index.js\",\"import\":\"Merriweather\",\"arguments\":[{\"adjustFontFallback\":true,\"preload\":true,\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-brand\"}],\"variableName\":\"merriweather\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.0.3_patch_hash=h6hqwh2okhqfs3o2wc7noh4dia_@babel+core@7.22.10_@playwright+test@1.49.1_4djjr5g64p5jtkgcav3xcc6gn4/node_modules/next/font/google/target.css?{\"path\":\"../../utils/s-next-font-open-sans/dist/index.js\",\"import\":\"Open_Sans\",\"arguments\":[{\"adjustFontFallback\":true,\"preload\":true,\"style\":[\"normal\",\"italic\"],\"subsets\":[\"hebrew\",\"latin\"],\"weight\":\"variable\",\"display\":\"swap\",\"variable\":\"--font-brand\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/apps/a-htz/src/app/_components/layout/BiDataLinks/BiDataLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/apps/a-htz/src/app/_components/layout/PlantUserCookie/PlantUserCookie.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/apps/a-htz/src/app/_components/layout/PreconnectLinks/PreconnectLinks.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/hooks/s-use-bi/dist/useBiDataContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/hooks/s-use-native-application/dist/useLoadEvent/useLoadEvent.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/hooks/s-use-once/dist/useOnce.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/animationReduced/animationReducedAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/articleCoverColor/articleCoverColorAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/articleId/articleIdAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/articleType/articleTypeAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/authors/authorsAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/clientTeasersOnPage/clientTeasersOnPageAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/contrastMode/contrastModeAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/cookie/cookieAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/countryCode/countryCodeAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/page/pageAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/pageStatusToken/pageStatusTokenAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/pageType/pageTypeAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/paywallType/paywallTypeAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/platform/platformAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/previewId/previewIdAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/readingHistory/readingHistoryAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/readingHistoryCount/readingHistoryCountAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/renderingKind/renderingKindAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/tags/tagsAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/user/userAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/userAgent/userAgentAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-atoms/dist/zenMode/zenModeAtom.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-browser-storage-utils/dist/utils/selectStorage.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-next-layout/dist/components/GlobalProviders/ClientGlobalProviders.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/utils/s-next-layout/dist/components/GlobalProviders/ClientState.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/utils/s-next-layout/dist/components/GlobalScripts/ClientConfigScript.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/utils/s-next-layout/dist/components/WrapGlobalFetch/WrapGloblFetch.js");
