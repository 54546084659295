import breakUrl from '../breakUrl';

const fullArticleUrlRegex =
  /(?:ty-article).*(?:[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})$/i;

const shortArticleUrlRegex =
  /^(?:\/(?:tmr|hrtz))?\/(?:[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})$/i;

function isArticleURL(url: string) {
  if (!url) return false;

  const { pathname } = breakUrl(url);

  const result = fullArticleUrlRegex.test(pathname) || shortArticleUrlRegex.test(pathname);

  return result;
}

export default isArticleURL;
